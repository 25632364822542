import {createContext, useContext} from 'react';
import {printerDataType} from "../types/printerTypes";

interface PrinterDataContextInterface{
    allPrinters: printerDataType[] | undefined,
    printers: printerDataType[] | undefined,
    loadingState: Boolean | undefined,
    setPrinters: any,
    setLoadingState: any,
    isLoading: Boolean,
    shouldReload: Boolean,
    setShouldReload: any,
    apiErrorFlag: Boolean,
}

export const PrinterDataContext = createContext<PrinterDataContextInterface>({
  allPrinters: undefined,
  printers: undefined,
  loadingState: false,
  setPrinters: {},
  setLoadingState: undefined,
  isLoading: false,
  shouldReload: true,
  setShouldReload: undefined,
  apiErrorFlag: false,
});

export function usePrinterDataContext(){
    const {
        allPrinters, printers, setPrinters, loadingState, setLoadingState, isLoading, shouldReload, setShouldReload, apiErrorFlag
    } = useContext(PrinterDataContext);

    if (printers === undefined || allPrinters === undefined){
        throw new Error("usePrinterDataContext must be used with a printerDataContext");
    }

    return { allPrinters, printers, setPrinters, loadingState, setLoadingState, isLoading, shouldReload, setShouldReload, apiErrorFlag };
}
